<template>
    <div>
        <CRow>
            <CCol md="12" lg="12">
                <CCard accent-color="info">
                    <CCardHeader>
                        <h4>Asignación Empresas a perfil</h4>
                    </CCardHeader>
                    <CCardBody style="overflow:auto;">
                        <CRow>
                            <CCol md="12">
                                <CAlert :show.sync="tiempoAlertaActualizar" closeButton v-show="mostrarEstadoMensajeActualizar" :color="estadoMensajeActualizar">
                                    {{mensajeActualizar}} 
                                </CAlert>
                            </CCol>
                        </CRow>
                        <CRow class="mb-2">
                            <CCol md="5">
                                <multiselect class="m-0 d-inline-block" 
                                    v-model="tipoBDSelected"
                                    deselect-label="" 
                                    label="nombre" 
                                    placeholder="Base de Datos" 
                                    select-label=""
                                    selectedLabel=""
                                    :custom-label="customNameTipoBD"
                                    :options="tipoBD" 
                                    :searchable="true"
                                    :loading="isLoadingTipoBD"
                                    >
                                </multiselect>
                            </CCol>
                            <CCol md="4">
                                <multiselect class="m-0 d-inline-block" 
                                    v-model="perfilSelected"
                                    deselect-label="" 
                                    label="nombrePerfil" 
                                    placeholder="Perfil" 
                                    select-label=""
                                    selectedLabel=""
                                    :custom-label="customNamePerfil"
                                    :options="perfiles" 
                                    :searchable="true"
                                    :loading="isLoadingPerfiles"
                                    @select="getEmpresasPerfil"
                                    :disabled="tipoBDSelected == null"
                                >
                                </multiselect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="12">
                                <a-table
                                    :columns="columnsEmpresas" 
                                    :data-source="empresasPerfil"
                                    :pagination="{ pageSize: 30 }"
                                    :scroll="{ y: 400 }"
                                    :loading="isLoadingEmpresas"
                                    class="components-table-demo-nested m-1" 
                                    size="small" 
                                    style="overflow-y:auto;">
                                    
                                    <CRow slot="codEmpresa" slot-scope="empresa">
                                        <CCol md="12">
                                            {{empresa.codEmpresa}}
                                        </CCol>
                                    </CRow>
                                    <CRow slot="nombreEmpresa" slot-scope="empresa">
                                        <CCol md="12">
                                            {{empresa.nombreEmpresa}}
                                        </CCol>
                                    </CRow>
                                    <CRow slot="asignado" slot-scope="empresa">
                                        <CCol md="12">
                                            <!--
                                                v-c-tooltip.hover="{content:(empresa.asignadoOtraEmpresa)?'Empresa ya se encuentra asignada a un usuario':'Asignar Empresa', placement:'left'}"
                                                :disabled="empresa.asignadoOtraEmpresa"
                                            -->
                                            <CSwitch
                                                width="150px"
                                                class="mx-1 align-middle" 
                                                size="sm"
                                                shape="pill"
                                                variant="outline"
                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                
                                                :checked="empresa.perfilEmp"
                                                @update:checked="empresa.perfilEmp = !empresa.perfilEmp; cambiarEstadoEmpresa(empresa)"
                                            />
                                        </CCol>
                                    </CRow>
                                </a-table>
                                <!--<CDataTable
                                    :hover="true"
                                    :striped="false"
                                    :bordered="true"
                                    :small="true"
                                    :fixed="true"
                                    :items="empresasPerfil"
                                    :fields="columnsEmpresas"
                                    :items-per-page="5"
                                    pagination
                                    style="max-height:auto; overflow: auto;"
                                >
                                <template #no-items-view>
                                    <center>{{textoSinEmpresas}}</center>
                                </template>
                                <td
                                    slot="asignado"
                                    slot-scope="{item}"
                                    class="text-center"
                                    style="vertical-align:middle;"
                                >
                                    <CSwitch
                                        width="150px"
                                        class="mx-1 align-middle" 
                                        size="sm"
                                        color="success" 
                                        shape="pill"
                                        variant="outline"
                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                        :checked="item.perfilEmp"
                                        @update:checked="item.perfilEmp = !item.perfilEmp; cambiarEstadoEmpresa(item)"
                                    />
                                </td>
                                <td
                                    slot="codEmpresa"
                                    slot-scope="{item}"
                                    class="text-center"
                                    style="vertical-align:middle;"
                                >
                                    {{item.codEmpresa}}
                                </td>
                                    
                                </CDataTable>-->
                            </CCol>
                        </CRow>
                        
                    </CCardBody>
                    <CCardFooter>
                        <CButton type="submit" size="sm" color="success" class="float-right" :disabled="!empresasCambiadas.length>0 || isLoadingUpdtPerfEmpresas" @click="actualizarEmpresasPerfil">
                            <CIcon name="cil-check-circle"/> Asignar empresas a {{(perfilSelected != null)?perfilSelected.nombrePerfil:""}}
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import Multiselect from 'vue-multiselect'
    import axios from "axios"

    
    export default {
        name: 'AsignacionEmpresas',
        components: {
            Multiselect
        },
        data () {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                
                //objetos seleccionados
                usuarioSelected: null,
                perfilSelected: null,
                tipoBDSelected: null,

                isLoadingPerfiles: false,
                isLoadingTipoBD: false,
                isLoadingUpdtPerfEmpresas: false,
                isLoadingEmpresas: false,

                tiempoAlertaActualizar: 10,
                estadoMensajeActualizar: "success",
                mostrarEstadoMensajeActualizar: false,
                mensajeActualizar: "",


                empresasCambiadas: [],
                textoSinEmpresas: "Seleccione un perfil",

                perfiles: [],
                empresasPerfil:[],
                
                tipoBD: [],

                //Columnas tablas
                columnsEmpresas: [
                    { title: 'Código Empresa', key: 'codEmpresa', scopedSlots: { customRender: 'codEmpresa' }, width: '10%', ellipsis: "true", align: 'center'},
                    { title: 'Rut Empresa', dataIndex: 'rutEmpresa', key: 'rutEmpresa', width: '20%', ellipsis: "true", align: 'center'},
                    { title: 'Nombre Empresa', key: 'nombreEmpresa', scopedSlots: { customRender: 'nombreEmpresa' }, width: '40%', align: 'left'},
                    { title: 'Asignado', key: 'asignado', scopedSlots: { customRender: 'asignado' }, width: '10%', align: 'center'},
                ],
                /*columnsEmpresas: [
                    { key: 'codEmpresa',label: 'Código', _classes: 'text-center', _style:'width:10%;'},
                    { key: 'rutEmpresa',label: 'Rut', _classes: 'text-center', _style:'width:30%;'},
                    { key: 'nombreEmpresa',label: 'Nombre', _classes: 'text-left', _style:'width:40%;'},
                    { key: 'asignado',label: 'Asignado', _classes: 'text-center', _style:'width:20%;'},
                ],*/
                columnsUsuarios:[
                    { key: 'userName',label: 'Usuario', _classes: 'text-center', _style:'width:10%;'},
                    { key: 'nombreUsuario',label: 'Nombre', _classes: 'text-center', _style:'width:30%;'},
                    { key: 'perfilAsignado',label: 'Perfil', _classes: 'text-left', _style:'width:40%;'},
                ],

                
            }
        },
        created: function(){},
        beforeMount() {

            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }

            this.getTipoBds()
            this.getPerfiles()
        }, 
        methods: {

            getTipoBds: function(){
                this.isLoadingTipoBD = true

                var url = cons.port+"://" + cons.ipServer + "/get_tiposbds/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    this.tipoBD = result.data;

                }, error => {
                    let mensaje = `Problema al obtener perfiles , ${error}`
                })
                .finally(() => {
                    this.isLoadingTipoBD = false
                });
            },
            
            getPerfiles: function(){
                this.isLoadingPerfiles = true

                var url = cons.port+"://" + cons.ipServer + "/get_perfiles/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    this.perfiles = result.data;

                }, error => {
                    let mensaje = `Problema al obtener perfiles , ${error}`
                })
                .finally(() => {
                    this.isLoadingPerfiles = false
                });

            },

            getEmpresasPerfil(perfil){
                this.isLoadingEmpresas = true
                this.textoSinEmpresas = []
                var idPerfil = perfil.idPerfil
                var bdSel = this.tipoBDSelected.valor
                var url = cons.port+"://" + cons.ipServer + "/get_empresas_usuario/"+tipo_db;
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.empresasPerfil = result.data;
                    if(result.data.length <= 0){
                        this.textoSinEmpresas = "No hay empresas"
                    }
                }, error => {
                    let mensaje = `Problema al obtener empresas , ${error}`
                    this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresas = false
                });
            },

            actualizarEmpresasPerfil(){

                this.isLoadingUpdtPerfEmpresas = true
                var bdSel = this.tipoBDSelected.valor
                var url = cons.port+"://" + cons.ipServer + "/set_empresas_perfil/"+this.perfilSelected.idPerfil+"/"+bdSel+"/"+JSON.stringify(this.empresasCambiadas)
                
                axios({
                    method: "GET", 
                    "url": url,
                }).then(result => {

                    this.getEmpresasPerfil(this.perfilSelected)
                    this.tiempoAlertaActualizar = 10
                    this.estadoMensajeActualizar = "success"
                    this.mostrarEstadoMensajeActualizar = true
                    this.mensajeActualizar = result.data

                }, error => {
                    let mensaje = `Problema al actualizar perfiles, ${error}`
                    
                    this.tiempoAlertaActualizar = 10
                    this.estadoMensajeActualizar = "danger"
                    this.mostrarEstadoMensajeActualizar = true
                    this.mensajeActualizar = mensaje
                })
                .finally(() => {
                    this.isLoadingUpdtPerfEmpresas = false
                });
            },

            cambiarEstadoEmpresa: function(item){

                var encontrado = false

                this.empresasCambiadas.forEach(emp => {
                    if(emp.idEmpresa == item.idEmpresa){
                        emp.estado = item.perfilEmp
                        encontrado = true
                    }
                });
                if(!encontrado){
                    this.empresasCambiadas.push({
                        "idEmpresa": item.idEmpresa,
                        "codEmpresa": item.codEmpresa,
                        "estado": item.perfilEmp
                    })
                }

            },

            customNameTipoBD(tipoBD){
                return `${tipoBD.nombre}`
            },

            customNamePerfil(perfil){
                return `${perfil.nombrePerfil}`
            },
            
        }
        
    }
</script>